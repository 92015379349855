import lookup from "country-code-lookup"

export enum LocationType {
  International = 1,
  Continent = 2,
  Country = 3,
  Miscellaneous = 9,
}

export interface LocationOption {
  type: LocationType
  code: string
  label: string
}

function split(str: string, index: number) {
  return [Number(str.slice(0, index)), str.slice(index)]
}

const countryLocationOptions: LocationOption[] = lookup.countries
  .sort((a, b) => (a.country > b.country ? 1 : -1))
  .map((x) => {
    return {
      type: LocationType.Country,
      code: x.iso3,
      label: x.country,
    }
  })

export const locationOptions: LocationOption[] = [
  { type: LocationType.International, code: "WWD", label: "Worldwide" },
  { type: LocationType.Continent, code: "FFF", label: "Africa" },
  { type: LocationType.Continent, code: "ATA", label: "Antarctica" },
  { type: LocationType.Continent, code: "ABB", label: "Asia" },
  { type: LocationType.Continent, code: "EEE", label: "Europe" },
  { type: LocationType.Continent, code: "NNN", label: "North America" },
  { type: LocationType.Continent, code: "UUU", label: "Oceania" },
  { type: LocationType.Continent, code: "SRR", label: "South America" },
  ...countryLocationOptions,
  { type: LocationType.Miscellaneous, code: "DAC", label: "DACH" },
]

export function parseLocation(location: string) {
  const [type, code] = split(location, 1)
  return locationOptions.find((x) => x.type === type && x.code === code)
}
