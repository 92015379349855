import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import Alt from "../components/Alt"
import CupEmblem from "../components/CupEmblem"
import Flag from "../components/Flag"
import Locations from "../components/Locations"
import PlayerName from "../components/PlayerName"
import TableLoading from "../components/TableLoading"
import Wheel from "../components/Wheel"
import environment from "../environment"
import { cupsMap } from "../models/cups"
import { Game } from "../models/game"
import { speedsMap } from "../models/speeds"
import { tracksMap } from "../models/tracks"
import { getLocationCookie, setLocationCookie } from "../utils/cookie"
import {
  createCustomSearchParams,
  equalSearchParams,
} from "../utils/search-params"

interface WorldRecordRow {
  cupName: string
  trackId: number
  trackName: string
  speed: number
  time: string
  motion: boolean
  playerId: string
  playerName: string
  playerAlpha3: string
  playerNation: string
  date: string
  isAlt: boolean
}

export default function WorldRecords() {
  const { game } = useParams()
  const cups = cupsMap[game as Game]
  const speeds = speedsMap[game as Game]
  const tracks = tracksMap[game as Game]

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [worldRecordRows, setWorldRecordRows] = useState([] as WorldRecordRow[])
  const [location, setLocation] = useState(
    searchParams.get("location") ?? getLocationCookie()
  )

  useEffect(() => {
    const loc = searchParams.get("location")
    if (loc) {
      setLocationCookie(loc)
      setLocation(loc)
    }
  }, [searchParams])

  useEffect(() => {
    const customSearchParams = createCustomSearchParams({ location: location })
    if (!equalSearchParams(customSearchParams, searchParams)) {
      setSearchParams(customSearchParams)
    }

    fetch(`${environment.domain}/api/${game}/wrs?location=${location}`)
      .then((res) => res.json() as unknown as any)
      .then((res) => {
        const rows = res.rows as any[]
        let worldRecordRows: WorldRecordRow[] = []
        cups.forEach((cup) => {
          tracks
            .filter((track) => track.cup === cup.id)
            .forEach((track) => {
              speeds.forEach((speed) => {
                const val = rows.find(
                  (x) => x.tid === track.id && x.speed === speed
                )
                worldRecordRows.push({
                  cupName: cup.name,
                  trackId: track.id,
                  trackName: track.name,
                  speed: speed,
                  time: val?.time ?? "",
                  motion: val?.motion,
                  playerId: val?.pid ?? "",
                  playerName: val?.name ?? "",
                  playerAlpha3: val?.alpha_3 ?? "",
                  playerNation: val?.country ?? "",
                  date: val?.date?.substring(0, 10) ?? "",
                  isAlt: val?.is_alt,
                })
              })
            })
        })
        setWorldRecordRows(worldRecordRows)
        setLoading(false)
      })
  }, [location, game, cups, speeds, tracks])

  const navigate = useNavigate()

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
        <Typography variant="h5">World Records</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Locations
          game={game as Game}
          location={location}
          setLocation={setLocation}
        ></Locations>
      </Box>
      {loading ? (
        <TableLoading />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="wrs">
            <TableHead>
              <TableRow>
                <TableCell>Cup</TableCell>
                <TableCell>Track</TableCell>
                {speeds.length > 1 ? <TableCell>Speed</TableCell> : null}
                <TableCell>Time</TableCell>
                <TableCell>Player</TableCell>
                <TableCell>Nation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {worldRecordRows.map((row, index) => (
                <TableRow key={`${row.trackId}${row.speed}`}>
                  {index % (speeds.length * 4) === 0 && (
                    <TableCell rowSpan={speeds.length * 4}>
                      <Box sx={{ textAlign: "center" }}>
                        <CupEmblem cupName={row.cupName} height="64" />
                      </Box>
                    </TableCell>
                  )}
                  {index % speeds.length === 0 && (
                    <TableCell rowSpan={speeds.length}>
                      <Link
                        component="button"
                        underline="none"
                        onClick={() =>
                          navigate(
                            `/${game}/trs/${
                              row.trackId
                            }?location=${getLocationCookie()}&speed=${
                              row.speed
                            }`
                          )
                        }
                      >
                        {row.trackName}
                      </Link>
                    </TableCell>
                  )}
                  {speeds.length > 1 ? (
                    <TableCell>
                      <Link
                        component="button"
                        underline="none"
                        onClick={() =>
                          navigate(
                            `/${game}/trs/${
                              row.trackId
                            }?location=${getLocationCookie()}&speed=${
                              row.speed
                            }`
                          )
                        }
                      >
                        {row.speed}cc
                      </Link>
                    </TableCell>
                  ) : null}
                  <TableCell>
                    {row.time}
                    {row.motion ? <Wheel /> : ""}
                  </TableCell>
                  <TableCell>
                    {row.playerId ? (
                      <Link
                        component="button"
                        underline="none"
                        onClick={() =>
                          navigate(
                            `/${game}/players/${
                              row.playerId
                            }?location=${getLocationCookie()}`
                          )
                        }
                      >
                        <PlayerName name={row.playerName} />
                      </Link>
                    ) : null}
                    {row.isAlt ? <Alt /> : null}
                  </TableCell>
                  <TableCell>
                    <Flag
                      alpha3={row.playerAlpha3}
                      country={row.playerNation}
                      tooltip={true}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}
