import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import Alt from "../components/Alt"
import Flag from "../components/Flag"
import Locations from "../components/Locations"
import PlayerName from "../components/PlayerName"
import TableLoading from "../components/TableLoading"
import Wheel from "../components/Wheel"
import environment from "../environment"
import { Game } from "../models/game"
import { speedsMap } from "../models/speeds"
import { tracksMap } from "../models/tracks"
import { getLocationCookie, setLocationCookie } from "../utils/cookie"
import {
  createCustomSearchParams,
  equalSearchParams,
} from "../utils/search-params"

interface TrackRankingTitle {
  trackName: string
  speed: number
}

interface TrackRankingRow {
  time: string
  motion: boolean
  playerId: string
  playerName: string
  playerAlpha3: string
  playerNation: string
  date: string
  isAlt: boolean
  rank: string
}

export default function TrackRanking() {
  let { game, tid } = useParams()
  const speeds = speedsMap[game as Game]
  const tracks = tracksMap[game as Game]

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [trackRanking, setTrackRanking] = useState(
    null as unknown as TrackRankingTitle
  )
  const [speed, setSpeed] = useState(searchParams.get("speed") ?? "150")
  const [location, setLocation] = useState(
    searchParams.get("location") ?? getLocationCookie()
  )
  const [trackRankingRows, setTrackRankingRows] = useState(
    [] as TrackRankingRow[]
  )
  const [highlight, setHighlight] = useState(
    Number(searchParams.get("highlight"))
  )
  const [page, setPage] = useState(highlight ? Math.ceil(highlight / 100) : 1)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleHighlight = (id: string) => {
    setTimeout(tryScroll, 0)

    function tryScroll() {
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }
  }

  useEffect(() => {
    const loc = searchParams.get("location")
    if (loc) {
      setLocationCookie(loc)
      setLocation(loc)
    }
    const speed = searchParams.get("speed")
    if (speed) {
      setSpeed(speed)
    }
  }, [searchParams])

  useEffect(() => {
    const customSearchParams = createCustomSearchParams({
      highlight: highlight,
      location: location,
      speed: speed,
    })
    if (!equalSearchParams(customSearchParams, searchParams)) {
      setSearchParams(customSearchParams)
    }

    const trackRanking: TrackRankingTitle = {
      trackName: tracks.find((x) => x.id === Number(tid))?.name ?? "",
      speed: Number(speed) ?? "",
    }
    setTrackRanking(trackRanking)

    const offset = (page - 1) * 100
    const limit = 100
    fetch(
      `${environment.domain}/api/${game}/trs/${tid}?limit=${limit}&location=${location}&offset=${offset}&speed=${speed}`
    )
      .then((res) => res.json() as unknown as any)
      .then((res) => {
        const totalCount = res.totalCount
        const rows = res.rows as any[]
        let trackRankingRows: TrackRankingRow[] = []
        rows.forEach((res) => {
          trackRankingRows.push({
            time: res?.time ? res.time : "",
            motion: res?.motion,
            playerId: res?.pid ? res.pid : "",
            playerName: res?.name !== undefined ? res.name : "",
            playerAlpha3: res?.alpha_3 ? res.alpha_3 : "",
            playerNation: res?.country ? res.country : "",
            date: res?.date ? res.date.substring(0, 10) : "",
            isAlt: res?.is_alt,
            rank: res?.rank ? res.rank : "",
          })
        })
        setTotalCount(totalCount)
        setTrackRankingRows(trackRankingRows)
        setLoading(false)
        if (
          highlight &&
          trackRankingRows.some((x) => Number(x.rank) === highlight)
        ) {
          handleHighlight(`rank${highlight}`)
          setHighlight(0)
        }
      })
  }, [location, game, tid, speed, page, speeds, tracks])

  const navigate = useNavigate()

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
        <Typography variant="h5">
          {trackRanking?.trackName}{" "}
          {speeds.length > 1 ? trackRanking?.speed + "cc" : ""}
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Locations
          game={game as Game}
          location={location}
          setLocation={setLocation}
        ></Locations>
      </Box>
      {loading ? (
        <TableLoading />
      ) : (
        <Box>
          <Stack spacing={2} sx={{ mb: 3 }}>
            <Pagination
              count={Math.ceil(totalCount / 100)}
              siblingCount={3}
              page={page}
              onChange={handleChange}
              sx={{ mx: "auto" }}
            />
          </Stack>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="trs">
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Nation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trackRankingRows.map((row) => (
                  <TableRow
                    key={`${row.rank}${row.playerId}`}
                    id={`rank${row.rank}`}
                    sx={{
                      backgroundColor:
                        Number(searchParams.get("highlight")) ===
                        Number(row.rank)
                          ? "rgba(255, 255, 0, 0.25)"
                          : "none",
                    }}
                  >
                    <TableCell>{row.rank}</TableCell>
                    <TableCell>
                      <Link
                        component="button"
                        underline="none"
                        onClick={() =>
                          navigate(
                            `/${game}/players/${
                              row.playerId
                            }?location=${getLocationCookie()}`
                          )
                        }
                      >
                        <PlayerName name={row.playerName} />
                      </Link>
                      {row.isAlt ? <Alt /> : null}
                    </TableCell>
                    <TableCell>
                      {row.time}
                      {row.motion ? <Wheel /> : ""}
                    </TableCell>
                    <TableCell>
                      <Flag
                        alpha3={row.playerAlpha3}
                        country={row.playerNation}
                        tooltip={true}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <Pagination
              count={Math.ceil(totalCount / 100)}
              siblingCount={10}
              page={page}
              onChange={handleChange}
              sx={{ mx: "auto" }}
            />
          </Stack>
        </Box>
      )}
    </Box>
  )
}
