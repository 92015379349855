import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import CupEmblem from "../components/CupEmblem"
import Flag from "../components/Flag"
import Locations from "../components/Locations"
import Mii from "../components/Mii"
import PlayerName from "../components/PlayerName"
import TableLoading from "../components/TableLoading"
import Wheel from "../components/Wheel"
import environment from "../environment"
import { cupsMap } from "../models/cups"
import { Game } from "../models/game"
import { speedsMap } from "../models/speeds"
import { tracksMap } from "../models/tracks"
import { getLocationCookie, setLocationCookie } from "../utils/cookie"
import {
  createCustomSearchParams,
  equalSearchParams,
} from "../utils/search-params"

interface PlayerTitle {
  playerId: string
  playerNnid: string
  playerName: string
  playerAlpha3: string
  playerNation: string
  playerRegion: string
  playerMii: string
  totalTimes: number
  isAlt: boolean
  mainName: string
  mainPid: string
  altNames: string
  altPids: string
  ft150Rank: number
  nft150Rank: number
  ft150RankTotal: number
  nft150RankTotal: number
}

interface PlayerRow {
  cupName: string
  trackId: number
  trackName: string
  speed: number
  time: string
  motion: boolean
  date: string
  rank: string
}

const maxPlayerWidth = 500

export default function Player() {
  const { game, pid } = useParams()
  const cups = cupsMap[game as Game]
  const speeds = speedsMap[game as Game]
  const tracks = tracksMap[game as Game]

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [playerTitle, setPlayerTitle] = useState(null as unknown as PlayerTitle)
  const [playerRows, setPlayerRows] = useState([] as PlayerRow[])
  const [location, setLocation] = useState(
    searchParams.get("location") ?? getLocationCookie()
  )

  useEffect(() => {
    const loc = searchParams.get("location")
    if (loc) {
      setLocationCookie(loc)
      setLocation(loc)
    }
  }, [searchParams])

  useEffect(() => {
    const customSearchParams = createCustomSearchParams({ location: location })
    if (!equalSearchParams(customSearchParams, searchParams)) {
      setSearchParams(customSearchParams)
    }

    fetch(
      `${environment.domain}/api/${game}/players/${pid}?location=${location}`
    )
      .then((res) => res.json() as unknown as any)
      .then((res) => {
        const totalCount = res.totalCount
        const player = res.player as any
        const rows = res.rows as any[]
        let playerRows: PlayerRow[] = []
        cups.forEach((cup) => {
          tracks
            .filter((track) => track.cup === cup.id)
            .forEach((track) => {
              speeds.forEach((speed) => {
                const val = rows.find(
                  (x) => x.tid === track.id && x.speed === speed
                )
                playerRows.push({
                  cupName: cup.name,
                  trackId: track.id,
                  trackName: track.name,
                  speed: speed,
                  time: val?.time ? val.time : "",
                  motion: val?.motion,
                  date: val?.date ? val.date.substring(0, 10) : "",
                  rank: val?.rank ? val.rank : "",
                })
              })
            })
        })
        const playerTitle: PlayerTitle = {
          playerId: player.pid ?? "",
          playerNnid: player.nnid ?? "",
          playerName: player.name ?? "",
          playerAlpha3: player.alpha_3 ?? "",
          playerNation: player.country ?? "",
          playerRegion: player.region ?? "",
          playerMii: player.mii ?? "",
          totalTimes: totalCount,
          isAlt: player.is_alt,
          mainName: player.main_name ?? "",
          mainPid: player.main_pid ?? "",
          altNames: player.alt_names ?? "",
          altPids: player.alt_pids ?? "",
          ft150Rank: player.ft_150_rank ?? 0,
          nft150Rank: player.nft_150_rank ?? 0,
          ft150RankTotal: player.ft_150_rank_total ?? 0,
          nft150RankTotal: player.nft_150_rank_total ?? 0,
        }
        setPlayerTitle(playerTitle)
        setPlayerRows(playerRows)
        setLoading(false)
      })
  }, [location, game, pid, cups, speeds, tracks])

  const navigate = useNavigate()

  return loading ? (
    <TableLoading />
  ) : !playerTitle.playerId ? (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Player Not Found: {pid}
      </Typography>
      <Typography>
        You must have at least one uploaded top 1000 ghost time. Please contact
        us on{" "}
        <Link
          component="button"
          underline="none"
          onClick={() => window.open(environment.discord, "_blank")}
          sx={{
            verticalAlign: "unset",
            fontFamily: "Quicksand,Azeret Mono",
            fontSize: "1rem",
          }}
        >
          discord
        </Link>{" "}
        if you think this is illegitimate.
      </Typography>
    </Box>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
        <Typography variant="h5">{playerTitle.playerName}</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Locations
          game={game as Game}
          location={location}
          setLocation={setLocation}
        ></Locations>
      </Box>
      {playerTitle.isAlt ? (
        <Alert severity="info" sx={{ mb: 3 }}>
          This is an alternate account to the main account{" "}
          <Link
            component="button"
            underline="none"
            onClick={() =>
              navigate(
                `/${game}/players/${
                  playerTitle.mainPid
                }?location=${getLocationCookie()}`
              )
            }
            sx={{
              verticalAlign: "unset",
              fontFamily: "Quicksand,Azeret Mono",
              fontSize: "14px",
            }}
          >
            <PlayerName name={playerTitle.mainName} />
          </Link>
          . Alternate accounts are not included in player rankings.
        </Alert>
      ) : null}
      <TableContainer
        sx={{ mx: "auto", mb: 3, maxWidth: maxPlayerWidth }}
        component={Paper}
      >
        <Table size="small" aria-label="player">
          <TableBody>
            <TableRow>
              {playerTitle.playerMii ? (
                <TableCell rowSpan={10}>
                  <Box sx={{ textAlign: "center" }}>
                    <Mii
                      data={playerTitle.playerMii}
                      height="128"
                      ftRank={playerTitle.ft150Rank}
                      nftRank={playerTitle.nft150Rank}
                      nftRankTotal={playerTitle.nft150RankTotal}
                    />
                  </Box>
                </TableCell>
              ) : null}
              <TableCell>Name</TableCell>
              <TableCell>
                <PlayerName name={playerTitle.playerName} />
              </TableCell>
            </TableRow>
            {playerTitle.altPids ? (
              <TableRow>
                <TableCell>Alts</TableCell>
                <TableCell>
                  {playerTitle.altPids.split(",").map((altPid, index) => (
                    <Link
                      style={{ display: "block" }}
                      component="button"
                      underline="none"
                      onClick={() =>
                        navigate(
                          `/${game}/players/${altPid}?location=${getLocationCookie()}`
                        )
                      }
                    >
                      <PlayerName
                        name={playerTitle.altNames.split(",")[index]}
                      />
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
            ) : null}
            {playerTitle.mainPid ? (
              <TableRow>
                <TableCell>Main</TableCell>
                <TableCell>
                  <Link
                    style={{ display: "block" }}
                    component="button"
                    underline="none"
                    onClick={() =>
                      navigate(
                        `/${game}/players/${
                          playerTitle.mainPid
                        }?location=${getLocationCookie()}`
                      )
                    }
                  >
                    <PlayerName name={playerTitle.mainName} />
                  </Link>
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>Nnid</TableCell>
              <TableCell>{playerTitle.playerNnid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>{playerTitle.playerId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nation</TableCell>
              <TableCell>
                <Flag
                  alpha3={playerTitle.playerAlpha3}
                  country={playerTitle.playerNation}
                  tooltip={false}
                />
                <span style={{ marginLeft: "10px" }}>
                  {playerTitle.playerNation}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Region</TableCell>
              <TableCell>{playerTitle.playerRegion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Times</TableCell>
              <TableCell>
                {playerTitle.totalTimes} / {tracks.length * speeds.length}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>FT Rank</TableCell>
              <TableCell>
                {!playerTitle.isAlt && playerTitle.ft150Rank ? (
                  <Link
                    component="button"
                    underline="none"
                    onClick={() =>
                      navigate(
                        `/${game}/prs?full=true&highlight=${
                          playerTitle.ft150Rank
                        }&location=${getLocationCookie()}&speed=150`
                      )
                    }
                  >
                    {`${playerTitle.ft150Rank} / ${playerTitle.ft150RankTotal}`}
                  </Link>
                ) : (
                  "???"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Non-FT Rank</TableCell>
              <TableCell>
                {!playerTitle.isAlt && playerTitle.nft150Rank ? (
                  <Link
                    component="button"
                    underline="none"
                    onClick={() =>
                      navigate(
                        `/${game}/prs?full=false&highlight=${
                          playerTitle.nft150Rank
                        }&location=${getLocationCookie()}&speed=150`
                      )
                    }
                  >
                    {`${playerTitle.nft150Rank} / ${playerTitle.nft150RankTotal}`}
                  </Link>
                ) : (
                  "???"
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="player">
          <TableHead>
            <TableRow>
              <TableCell>Cup</TableCell>
              <TableCell>Track</TableCell>
              {speeds.length > 1 ? <TableCell>Speed</TableCell> : null}
              <TableCell>Time</TableCell>
              <TableCell>Rank</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playerRows.map((row, index) => (
              <TableRow key={`${row.trackId}${row.speed}`}>
                {index % (speeds.length * 4) === 0 && (
                  <TableCell rowSpan={speeds.length * 4}>
                    <Box sx={{ textAlign: "center" }}>
                      <CupEmblem cupName={row.cupName} height="64" />
                    </Box>
                  </TableCell>
                )}
                {index % speeds.length === 0 && (
                  <TableCell rowSpan={speeds.length}>
                    <Link
                      component="button"
                      underline="none"
                      onClick={() =>
                        navigate(
                          `/${game}/trs/${
                            row.trackId
                          }?location=${getLocationCookie()}&speed=${row.speed}`
                        )
                      }
                    >
                      {row.trackName}
                    </Link>
                  </TableCell>
                )}
                {speeds.length > 1 ? (
                  <TableCell>
                    <Link
                      component="button"
                      underline="none"
                      onClick={() =>
                        navigate(
                          `/${game}/trs/${
                            row.trackId
                          }?location=${getLocationCookie()}&speed=${row.speed}`
                        )
                      }
                    >
                      {row.speed}cc
                    </Link>
                  </TableCell>
                ) : null}
                <TableCell>
                  {row.time ? (
                    <Link
                      component="button"
                      underline="none"
                      onClick={() =>
                        navigate(
                          `/${game}/trs/${row.trackId}?highlight=${
                            row.rank
                          }&location=${getLocationCookie()}&speed=${row.speed}`
                        )
                      }
                    >
                      {row.time}
                    </Link>
                  ) : (
                    ""
                  )}
                  {row.motion ? <Wheel /> : ""}
                </TableCell>
                <TableCell>{row.rank}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
