import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import Link from "@mui/material/Link"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import Flag from "../components/Flag"
import Locations from "../components/Locations"
import PlayerName from "../components/PlayerName"
import TableLoading from "../components/TableLoading"
import environment from "../environment"
import { Game } from "../models/game"
import { speedsMap } from "../models/speeds"
import { tracksMap } from "../models/tracks"
import { getLocationCookie, setLocationCookie } from "../utils/cookie"
import {
  createCustomSearchParams,
  equalSearchParams,
} from "../utils/search-params"

interface PlayerRankingRow {
  playerId: string
  playerName: string
  playerAlpha3: string
  playerNation: string
  totalTimes: string
  averageFinish: string
  rank: string
}

export default function PlayerRankings() {
  let { game } = useParams()
  const speeds = speedsMap[game as Game]
  const tracks = tracksMap[game as Game]

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [playerRankingRows, setPlayerRankingRows] = useState(
    [] as PlayerRankingRow[]
  )
  const [speed, setSpeed] = useState(searchParams.get("speed") ?? "150")
  const [full, setFull] = useState(searchParams.get("full") === "true")
  const [location, setLocation] = useState(
    searchParams.get("location") ?? getLocationCookie()
  )
  const [highlight, setHighlight] = useState(
    Number(searchParams.get("highlight"))
  )
  const [page, setPage] = useState(highlight ? Math.ceil(highlight / 100) : 1)

  const handleChange = (event: any, value: number) => {
    setPage(value)
  }

  const handleHighlight = (id: string) => {
    setTimeout(tryScroll, 0)

    function tryScroll() {
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }
  }

  useEffect(() => {
    const full = searchParams.get("full")
    if (full) {
      setFull(full === "true")
    }
    const loc = searchParams.get("location")
    if (loc) {
      setLocationCookie(loc)
      setLocation(loc)
    }
    const speed = searchParams.get("speed")
    if (speed) {
      setSpeed(speed)
    }
  }, [searchParams])

  useEffect(() => {
    const customSearchParams = createCustomSearchParams({
      full: full,
      highlight: highlight,
      location: location,
      speed: speed,
    })
    if (!equalSearchParams(customSearchParams, searchParams)) {
      setSearchParams(customSearchParams)
    }

    const offset = (page - 1) * 100
    const limit = 100
    fetch(
      `${environment.domain}/api/${game}/prs?full=${full}&limit=${limit}&location=${location}&offset=${offset}&speed=${speed}`
    )
      .then((res) => res.json() as unknown as any)
      .then((res) => {
        const totalCount = res.totalCount
        const rows = res.rows as any[]
        let playerRankingRows: PlayerRankingRow[] = []
        rows.forEach((res) => {
          playerRankingRows.push({
            playerId: res?.pid ? res.pid : "",
            playerName: res?.name !== undefined ? res.name : "",
            playerAlpha3: res?.alpha_3 ? res.alpha_3 : "",
            playerNation: res?.country ? res.country : "",
            totalTimes: res?.total_times ? res.total_times : "",
            averageFinish: res?.average_finish ? res.average_finish : "",
            rank: res?.rank ? res.rank : "",
          })
        })
        setTotalCount(totalCount)
        setPlayerRankingRows(playerRankingRows)
        setLoading(false)
        if (
          highlight &&
          playerRankingRows.some((x) => Number(x.rank) === highlight)
        ) {
          handleHighlight(`rank${highlight}`)
          setHighlight(0)
        }
      })
  }, [location, game, speed, full, page, speeds, tracks])

  const navigate = useNavigate()

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
        <Typography variant="h5">Player Rankings</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Locations
          game={game as Game}
          location={location}
          setLocation={setLocation}
        ></Locations>
      </Box>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <FormControlLabel
            label={`Full Timesheet (${tracks.length * speeds.length} Times)`}
            control={
              <Switch
                checked={full}
                onChange={(event: any) => {
                  setFull(event.target.checked)
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </CardContent>
      </Card>
      {loading ? (
        <TableLoading />
      ) : (
        <Box>
          <Stack spacing={2} sx={{ mb: 3 }}>
            <Pagination
              count={Math.ceil(totalCount / 100)}
              siblingCount={3}
              page={page}
              onChange={(event: any, value: number) => {
                setPage(value)
              }}
              sx={{ mx: "auto" }}
            />
          </Stack>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="prs">
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Nation</TableCell>
                  <TableCell>Total Times</TableCell>
                  <TableCell>Average Finish</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playerRankingRows.map((row) => (
                  <TableRow
                    key={`${row.rank}${row.playerId}`}
                    id={`rank${row.rank}`}
                    sx={{
                      backgroundColor:
                        Number(searchParams.get("highlight")) ===
                        Number(row.rank)
                          ? "rgba(255, 255, 0, 0.25)"
                          : "none",
                    }}
                  >
                    <TableCell>{row.rank}</TableCell>
                    <TableCell>
                      <Link
                        component="button"
                        underline="none"
                        onClick={() =>
                          navigate(
                            `/${game}/players/${
                              row.playerId
                            }?location=${getLocationCookie()}`
                          )
                        }
                      >
                        <PlayerName name={row.playerName} />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Flag
                        alpha3={row.playerAlpha3}
                        country={row.playerNation}
                        tooltip={true}
                      />
                    </TableCell>
                    <TableCell>{row.totalTimes}</TableCell>
                    <TableCell>{row.averageFinish}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <Pagination
              count={Math.ceil(totalCount / 100)}
              siblingCount={3}
              page={page}
              onChange={handleChange}
              sx={{ mx: "auto" }}
            />
          </Stack>
        </Box>
      )}
    </Box>
  )
}
