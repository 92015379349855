import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import { useEffect } from "react"
import { isMobile } from "react-device-detect"
import { Route, Routes, useLocation } from "react-router-dom"

import NotFound from "../components/NotFound"
import environment from "../environment"
import Developer from "../pages/Developer"
import Player from "../pages/Player"
import PlayerRankings from "../pages/PlayerRankings"
import Players from "../pages/Players"
import Questions from "../pages/Questions"
import TrackRanking from "../pages/TrackRanking"
import TrackRankings from "../pages/TrackRankings"
import Welcome from "../pages/Welcome"
import WorldRecords from "../pages/WorldRecords"
import { getOpenGraphMeta } from "../scripts/open-graph-meta"

const minWidth = isMobile ? 650 : 850
const maxWidth = 850

export default function MainContext() {
  const location = useLocation()

  useEffect(() => {
    const og = getOpenGraphMeta(environment.domain, location.pathname + location.search, null)
    document.title = og.title
    document.querySelector('meta[name="title"]')?.setAttribute("content", og.title)
    document.querySelector('meta[name="description"]')?.setAttribute("content", og.description)
  }, [location])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box sx={{ minWidth, maxWidth }}>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/:game/wrs" element={<WorldRecords />} />
            <Route path="/:game/trs" element={<TrackRankings />} />
            <Route path="/:game/trs/:tid" element={<TrackRanking />} />
            <Route path="/:game/players" element={<Players />} />
            <Route path="/:game/players/:pid" element={<Player />} />
            <Route path="/:game/prs" element={<PlayerRankings />} />
            <Route path="/faq" element={<Questions />} />
            <Route path="/dev" element={<Developer />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  )
}
