import CodeIcon from "@mui/icons-material/Code"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import EqualizerIcon from "@mui/icons-material/Equalizer"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import HomeIcon from "@mui/icons-material/Home"
import MenuIcon from "@mui/icons-material/Menu"
import PersonIcon from "@mui/icons-material/Person"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import { Divider, IconButton } from "@mui/material"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { createSvgIcon } from "@mui/material/utils"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import environment from "../environment"
import { DrawerInfo } from "../models/drawer"
import { Game } from "../models/game"
import { getLocationCookie } from "../utils/cookie"

interface MainDrawerProps {
  drawerInfo: DrawerInfo
  setDrawerInfo: React.Dispatch<React.SetStateAction<DrawerInfo>>
  game: Game
  setGame: React.Dispatch<React.SetStateAction<Game>>
}

interface MenuItem {
  text: string
  icon: any
  path: string
  gameHeader: boolean
  internal: boolean
}

const DiscordIcon = createSvgIcon(
  <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028 14.09 14.09 0 0 0 1.226-1.994.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z" />,
  "Discord"
)

function pathSubset(pathname: string, item: MenuItem): boolean {
  const index = item.gameHeader ? 2 : 1
  return (
    item.internal &&
    pathname.split("/")?.[index]?.split("?")?.[0] ===
      item.path.split("/")?.[index]?.split("?")?.[0]
  )
}

export default function MainDrawer(props: MainDrawerProps) {
  const { drawerInfo, setDrawerInfo, game, setGame } = props

  const [color, setColor] = useState<"primary" | "secondary">("primary")

  useEffect(() => {
    setColor(game === Game.MK8 ? "primary" : "secondary")
  }, [game])

  const navigate = useNavigate()
  const location = useLocation()

  const gameMenuItems: MenuItem[] = [
    {
      text: Game.MK8,
      icon: null,
      path: "",
      gameHeader: false,
      internal: true,
    },
    {
      text: Game.MK8DX,
      icon: null,
      path: "",
      gameHeader: false,
      internal: true,
    },
  ]

  const topMenuItems: MenuItem[] = [
    {
      text: "Welcome",
      icon: <HomeIcon color={color} />,
      path: "/",
      gameHeader: false,
      internal: true,
    },
    {
      text: "World Records",
      icon: <EmojiEventsIcon color={color} />,
      path: `/${game}/wrs?location=LOCATION_COOKIE`,
      gameHeader: true,
      internal: true,
    },
    {
      text: "Track Rankings",
      icon: <FormatListNumberedIcon color={color} />,
      path: `/${game}/trs`,
      gameHeader: true,
      internal: true,
    },
    {
      text: "Players",
      icon: <PersonIcon color={color} />,
      path: `/${game}/players`,
      gameHeader: true,
      internal: true,
    },
    {
      text: "Player Rankings",
      icon: <EqualizerIcon color={color} />,
      path: `/${game}/prs?full=true&location=LOCATION_COOKIE&speed=150`,
      gameHeader: true,
      internal: true,
    },
  ]

  const bottomMenuItems = [
    {
      text: "FAQ",
      icon: <QuestionMarkIcon color={color} />,
      path: "/faq",
      gameHeader: false,
      internal: true,
    },
    {
      text: "Developer",
      icon: <CodeIcon color={color} />,
      path: "/dev",
      gameHeader: false,
      internal: true,
    },
    {
      text: "Discord",
      icon: <DiscordIcon color={color} />,
      path: `${environment.discord}`,
      gameHeader: false,
      internal: false,
    },
  ]

  return (
    <Drawer
      sx={{
        width: drawerInfo.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerInfo.width,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ pl: "8px !important" }}>
        <IconButton
          color="inherit"
          onClick={() => {
            const newDrawerInfo = {
              open: !drawerInfo.open,
              width: !drawerInfo.open ? 240 : 58,
            } as DrawerInfo
            setDrawerInfo(newDrawerInfo)
          }}
        >
          <MenuIcon />
        </IconButton>
        {drawerInfo.open ? (
          <Typography
            variant="h6"
            sx={{ ml: "auto", bottom: "1px", position: "relative" }}
          >
            mkdatabase.com
          </Typography>
        ) : null}
      </Toolbar>
      <List sx={{ p: 0 }}>
        {gameMenuItems.map((item) => (
          <Tooltip
            key={item.text}
            title={item.text === Game.MK8DX ? "Coming Soon" : ""}
            placement="right"
          >
            <div>
              <ListItem
                button
                onClick={() => {
                  const nextGame = item.text as Game
                  setGame(nextGame)
                  navigate("/")
                }}
                disabled={item.text === Game.MK8DX}
                sx={(theme) => ({
                  height: "46px",
                  backgroundColor:
                    game === item.text ? theme.palette.action.selected : "none",
                })}
              >
                <img
                  src={require("../assets/" + item.text + "_logo_full.png")}
                  alt={game}
                  height="28"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    filter:
                      "drop-shadow(1px 1px 1px rgba(127, 127, 127, 0.25))",
                    position: "relative",
                    right: "0px",
                    display: !drawerInfo.open ? "none" : "inherit",
                  }}
                />
                <img
                  src={require("../assets/" + item.text + "_logo.png")}
                  alt={game}
                  height="28"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    filter:
                      "drop-shadow(1px 1px 1px rgba(127, 127, 127, 0.25))",
                    position: "relative",
                    right: "2px",
                    display: drawerInfo.open ? "none" : "inherit",
                  }}
                />
              </ListItem>
            </div>
          </Tooltip>
        ))}
      </List>
      <Divider></Divider>
      <List sx={{ p: 0 }}>
        {topMenuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => {
              if (item.internal) {
                const path = item.path.replace(
                  "LOCATION_COOKIE",
                  getLocationCookie()
                )
                navigate(path)
              } else {
                window.open(item.path, "_blank")
              }
            }}
            sx={(theme) => ({
              height: "46px",
              backgroundColor: pathSubset(location.pathname, item)
                ? theme.palette.action.selected
                : "none",
            })}
          >
            <ListItemIcon sx={{ minWidth: "40px" }}>{item.icon}</ListItemIcon>
            {drawerInfo.open ? <ListItemText primary={item.text} /> : null}
          </ListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }}></Box>
      <List sx={{ p: 0 }}>
        {bottomMenuItems.map((item) => (
          <Tooltip key={item.text} title={""} placement="right">
            <div>
              <ListItem
                button
                key={item.text}
                onClick={() => {
                  if (item.internal) {
                    navigate(item.path)
                  } else {
                    window.open(item.path, "_blank")
                  }
                }}
                sx={(theme) => ({
                  height: "46px",
                  backgroundColor: pathSubset(location.pathname, item)
                    ? theme.palette.action.selected
                    : "none",
                })}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  {item.icon}
                </ListItemIcon>
                {drawerInfo.open ? <ListItemText primary={item.text} /> : null}
              </ListItem>
            </div>
          </Tooltip>
        ))}
      </List>
    </Drawer>
  )
}
