import Tooltip from "@mui/material/Tooltip"

export default function Required() {
  return (
    <Tooltip title="Required" placement="right">
      <sup
        style={{
          color: "rgba(255,0,0,0.75)",
          fontSize: "16px",
          lineHeight: "0rem",
          userSelect: "none",
          position: "relative",
          top: "2px",
        }}
      >
        *
      </sup>
    </Tooltip>
  )
}
