import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import CupEmblem from "../components/CupEmblem"
import Locations from "../components/Locations"
import { cupsMap } from "../models/cups"
import { Game } from "../models/game"
import { speedsMap } from "../models/speeds"
import { tracksMap } from "../models/tracks"
import { getLocationCookie } from "../utils/cookie"

export default function TrackRankings() {
  const { game } = useParams()
  const cups = cupsMap[game as Game]
  const speeds = speedsMap[game as Game]
  const tracks = tracksMap[game as Game]

  const [speed, setSpeed] = useState("150")
  const [location, setLocation] = useState(getLocationCookie())

  const navigate = useNavigate()

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSpeed: string
  ) => {
    setSpeed(newSpeed)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
        <Typography variant="h5">Track Rankings</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Locations
          game={game as Game}
          location={location}
          setLocation={setLocation}
        ></Locations>
      </Box>
      {speeds.length > 1 ? (
        <ToggleButtonGroup
          color="primary"
          value={speed}
          exclusive
          onChange={handleChange}
          sx={{ mb: 3, display: "block", textAlign: "center" }}
        >
          {speeds.map((speed) => (
            <ToggleButton value={String(speed)}>{speed}cc</ToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : null}
      <Grid container spacing={2}>
        {cups.map((cup) => (
          <Grid item xs={3}>
            <Box sx={{ textAlign: "center", mt: 2, mb: 1 }}>
              <CupEmblem cupName={cup.name} height="64" />
            </Box>
            <Card sx={{ mb: 1 }}>
              <List sx={{ p: 0 }}>
                {tracks
                  .filter((x) => x.cup === cup.id)
                  .map((track) => (
                    <ListItem
                      button
                      key={track.id}
                      onClick={() => {
                        navigate(
                          `/${game}/trs/${
                            track.id
                          }?location=${getLocationCookie()}&speed=${speed}`
                        )
                      }}
                      sx={{ px: "0.6rem", py: "0.35rem" }}
                    >
                      <ListItemText>
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            lineHeight: 1,
                          }}
                        >
                          {track.name}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
              </List>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
