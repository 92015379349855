interface Environment {
  discord: string
  domain: string
}

const production: Environment = {
  discord: "https://discord.com/invite/zsv5h47dgT",
  domain: "https://www.mkdatabase.com",
}

const development: Environment = {
  discord: "https://discord.com/invite/zsv5h47dgT",
  domain: "http://localhost:5000",
}

export default process.env.NODE_ENV === "production" ? production : development
