import Tooltip from "@mui/material/Tooltip"

export default function Alt() {
  return (
    <Tooltip title="Alternate Account" placement="right">
      <sup
        style={{
          fontSize: "8px",
          lineHeight: "0.5rem",
          userSelect: "none",
          position: "relative",
          bottom: "4px",
          letterSpacing: "-0.75px",
        }}
      >
        [alt]
      </sup>
    </Tooltip>
  )
}
