import { useTheme } from "@mui/material/styles"
import Tooltip from "@mui/material/Tooltip"

export default function Wheel() {
  const filter = useTheme().palette.mode === 'dark' ? "invert(1)" : ""
  
  return (
    <Tooltip title="Motion Controls Used" placement="right">
      <span>
        <img
          src={require("../assets/wheel.png")}
          alt="Motion Controls Used"
          height="12"
          style={{
            position: "relative",
            top: "2px",
            marginLeft: "6px",
            filter: filter
          }}
        />
      </span>
    </Tooltip>
  )
}
