import { Game } from "./game"

export interface Cup {
  id: number
  name: string
  icon: string
}

export const cupsMap: Record<Game, Cup[]> = {
  [Game.MK8]: [
    { id: 0, name: "Mushroom", icon: "" },
    { id: 1, name: "Flower", icon: "" },
    { id: 2, name: "Star", icon: "" },
    { id: 3, name: "Special", icon: "" },
    { id: 4, name: "Shell", icon: "" },
    { id: 5, name: "Banana", icon: "" },
    { id: 6, name: "Leaf", icon: "" },
    { id: 7, name: "Lightning", icon: "" },
    { id: 8, name: "Egg", icon: "" },
    { id: 9, name: "Triforce", icon: "" },
    { id: 10, name: "Crossing", icon: "" },
    { id: 11, name: "Bell", icon: "" },
  ],
  [Game.MK8DX]: [
    { id: 0, name: "Mushroom", icon: "" },
    { id: 1, name: "Flower", icon: "" },
    { id: 2, name: "Star", icon: "" },
    { id: 3, name: "Special", icon: "" },
    { id: 4, name: "Shell", icon: "" },
    { id: 5, name: "Banana", icon: "" },
    { id: 6, name: "Leaf", icon: "" },
    { id: 7, name: "Lightning", icon: "" },
    { id: 8, name: "Egg", icon: "" },
    { id: 9, name: "Triforce", icon: "" },
    { id: 10, name: "Crossing", icon: "" },
    { id: 11, name: "Bell", icon: "" },
    // { id: 12, name: "Golden Dash", icon: "" },
    // { id: 13, name: "Lucky Cat", icon: "" },
    // { id: 14, name: "Turnip", icon: "" },
    // { id: 15, name: "Propeller", icon: "" },
    // { id: 16, name: "Rock", icon: "" },
    // { id: 17, name: "Moon", icon: "" },
    // { id: 18, name: "Fruit", icon: "" },
    // { id: 19, name: "Boomerang", icon: "" },
    // { id: 20, name: "Feather", icon: "" },
    // { id: 21, name: "Cherry", icon: "" },
    // { id: 22, name: "Acorn", icon: "" },
    // { id: 23, name: "Spiny", icon: "" },
  ],
}
