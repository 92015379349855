import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useLocation } from "react-router-dom"

export default function NotFound() {
  const location = useLocation()

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Page Not Found: {location.pathname}
      </Typography>
      <img src={require("../assets/dk_wheel.gif")} alt="DK Wheel" />
    </Box>
  )
}
