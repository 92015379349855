import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Chip from "@mui/material/Chip"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import Required from "../components/Required"
import { LocationType, locationOptions } from "../models/location"

export default function Developer() {
  const [expanded, setExpanded] = useState<string | false>(false)
  const [expandedExtra, setExpandedExtra] = useState<string | false>(false)

  const handleExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  const handleExpandedExtra =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedExtra(isExpanded ? panel : false)
    }
  const handleLocationCodes = () => {
    document
      .getElementById("location-codes")
      ?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Public API
      </Typography>
      <Alert severity="warning" sx={{ mb: 3 }}>
        Currently all mk8dx records are dummy data and is still a work in
        progress.
      </Alert>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography>
            hostname:{" "}
            <Link
              component="button"
              underline="none"
              onClick={() =>
                window.open("https://www.mkdatabase.com/", "_blank")
              }
              sx={{
                verticalAlign: "unset",
                fontFamily: "Quicksand,Azeret Mono",
                fontSize: "1rem",
              }}
            >
              https://www.mkdatabase.com/
            </Link>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Accordion
            disableGutters={true}
            elevation={0}
            sx={{
              "&.Mui-expanded:before": {
                opacity: 1,
              },
            }}
            expanded={expanded === "player"}
            onChange={handleExpanded("player")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`player-content`}
              id={`player-header`}
              sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
            >
              <Chip label="GET" size="small" color="success" />
              <Typography sx={{ ml: 2 }}>/api/:game/players/:pid</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Typography
                sx={{ color: "text.secondary", float: "right", mr: 2 }}
              >
                Player
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 3 }}>
              <Typography sx={{ mb: 2 }}>Parameters</Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="player">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Values</TableCell>
                      <TableCell>Default</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        game
                        <Required />
                      </TableCell>
                      <TableCell>Game</TableCell>
                      <TableCell>param</TableCell>
                      <TableCell>"mk8" | "mk8dx"</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        pid
                        <Required />
                      </TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          underline="none"
                          onClick={() =>
                            window.open(
                              "https://github.com/kinnay/NintendoClients/wiki/NEX-Common-Types#pid",
                              "_blank"
                            )
                          }
                        >
                          Player Id
                        </Link>
                      </TableCell>
                      <TableCell>param</TableCell>
                      <TableCell>any</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>location</TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          underline="none"
                          onClick={() => handleLocationCodes()}
                        >
                          Location Codes
                        </Link>
                      </TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>string</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ my: 2 }}>Response</Typography>
              <Box component={Paper} sx={{ p: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Azeret Mono",
                    fontSize: "0.75rem",
                    lineHeight: 1.5,
                  }}
                >
                  <div>&#123;</div>
                  <div style={{ paddingLeft: "2rem" }}>player: any,</div>
                  <div style={{ paddingLeft: "2rem" }}>totalCount: number,</div>
                  <div style={{ paddingLeft: "2rem" }}>
                    filteredCount: number,
                  </div>
                  <div style={{ paddingLeft: "2rem" }}>rows: any[]</div>
                  <div>&#125;</div>
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters={true}
            elevation={0}
            sx={{
              "&.Mui-expanded:before": {
                opacity: 1,
              },
            }}
            expanded={expanded === "prs"}
            onChange={handleExpanded("prs")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`prs-content`}
              id={`prs-header`}
              sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
            >
              <Chip label="GET" size="small" color="success" />
              <Typography sx={{ ml: 2 }}>/api/:game/prs</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Typography
                sx={{ color: "text.secondary", float: "right", mr: 2 }}
              >
                Player Rankings
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 3 }}>
              <Typography sx={{ mb: 2 }}>Parameters</Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="prs">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Values</TableCell>
                      <TableCell>Default</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        game
                        <Required />
                      </TableCell>
                      <TableCell>Game</TableCell>
                      <TableCell>param</TableCell>
                      <TableCell>"mk8" | "mk8dx"</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>full</TableCell>
                      <TableCell>Full Timesheet</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>"true" | "false"</TableCell>
                      <TableCell>true</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>speed</TableCell>
                      <TableCell>Speed in CC</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>"150" | "200"</TableCell>
                      <TableCell>150</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>location</TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          underline="none"
                          onClick={() => handleLocationCodes()}
                        >
                          Location Codes
                        </Link>
                      </TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>string</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>offset</TableCell>
                      <TableCell>Number of rows to be skipped</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>number</TableCell>
                      <TableCell>0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>limit</TableCell>
                      <TableCell>Number of rows to be returned</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>number</TableCell>
                      <TableCell>1000</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ my: 2 }}>Response</Typography>
              <Box component={Paper} sx={{ p: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Azeret Mono",
                    fontSize: "0.75rem",
                    lineHeight: 1.5,
                  }}
                >
                  <div>&#123;</div>
                  <div style={{ paddingLeft: "2rem" }}>totalCount: number,</div>
                  <div style={{ paddingLeft: "2rem" }}>
                    filteredCount: number,
                  </div>
                  <div style={{ paddingLeft: "2rem" }}>rows: any[]</div>
                  <div>&#125;</div>
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters={true}
            elevation={0}
            sx={{
              "&.Mui-expanded:before": {
                opacity: 1,
              },
            }}
            expanded={expanded === "trs"}
            onChange={handleExpanded("trs")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`trs-content`}
              id={`trs-header`}
              sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
            >
              <Chip label="GET" size="small" color="success" />
              <Typography sx={{ ml: 2 }}>/api/:game/trs/:tid</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Typography
                sx={{ color: "text.secondary", float: "right", mr: 2 }}
              >
                Track Rankings
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 3 }}>
              <Typography sx={{ mb: 2 }}>Parameters</Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="trs">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Values</TableCell>
                      <TableCell>Default</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        game
                        <Required />
                      </TableCell>
                      <TableCell>Game</TableCell>
                      <TableCell>param</TableCell>
                      <TableCell>"mk8" | "mk8dx"</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        tid
                        <Required />
                      </TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          underline="none"
                          onClick={() =>
                            window.open(
                              "https://github.com/kinnay/NintendoClients/wiki/Mario-Kart-8-Track-IDs",
                              "_blank"
                            )
                          }
                        >
                          Track Id
                        </Link>
                      </TableCell>
                      <TableCell>param</TableCell>
                      <TableCell>number</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>speed</TableCell>
                      <TableCell>Speed in CC</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>"150" | "200"</TableCell>
                      <TableCell>150</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>location</TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          underline="none"
                          onClick={() => handleLocationCodes()}
                        >
                          Location Codes
                        </Link>
                      </TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>string</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>offset</TableCell>
                      <TableCell>Number of rows to be skipped</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>number</TableCell>
                      <TableCell>0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>limit</TableCell>
                      <TableCell>Number of rows to be returned</TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>number</TableCell>
                      <TableCell>1000</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ my: 2 }}>Response</Typography>
              <Box component={Paper} sx={{ p: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Azeret Mono",
                    fontSize: "0.75rem",
                    lineHeight: 1.5,
                  }}
                >
                  <div>&#123;</div>
                  <div style={{ paddingLeft: "2rem" }}>totalCount: number,</div>
                  <div style={{ paddingLeft: "2rem" }}>
                    filteredCount: number,
                  </div>
                  <div style={{ paddingLeft: "2rem" }}>rows: any[]</div>
                  <div>&#125;</div>
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters={true}
            elevation={0}
            sx={{
              "&.Mui-expanded:before": {
                opacity: 1,
              },
            }}
            expanded={expanded === "wrs"}
            onChange={handleExpanded("wrs")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`wrs-content`}
              id={`wrs-header`}
              sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
            >
              <Chip label="GET" size="small" color="success" />
              <Typography sx={{ ml: 2 }}>/api/:game/wrs</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Typography
                sx={{ color: "text.secondary", float: "right", mr: 2 }}
              >
                World Records
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 3 }}>
              <Typography sx={{ mb: 2 }}>Parameters</Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="wrs">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Values</TableCell>
                      <TableCell>Default</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        game
                        <Required />
                      </TableCell>
                      <TableCell>Game</TableCell>
                      <TableCell>param</TableCell>
                      <TableCell>"mk8" | "mk8dx"</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>location</TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          underline="none"
                          onClick={() => handleLocationCodes()}
                        >
                          Location Codes
                        </Link>
                      </TableCell>
                      <TableCell>query</TableCell>
                      <TableCell>string</TableCell>
                      <TableCell>N/A</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ my: 2 }}>Response</Typography>
              <Box component={Paper} sx={{ p: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Azeret Mono",
                    fontSize: "0.75rem",
                    lineHeight: 1.5,
                  }}
                >
                  <div>&#123;</div>
                  <div style={{ paddingLeft: "2rem" }}>totalCount: number,</div>
                  <div style={{ paddingLeft: "2rem" }}>
                    filteredCount: number,
                  </div>
                  <div style={{ paddingLeft: "2rem" }}>rows: any[]</div>
                  <div>&#125;</div>
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Accordion
            id="location-codes"
            disableGutters={true}
            elevation={0}
            sx={{
              "&.Mui-expanded:before": {
                opacity: 1,
              },
            }}
            expanded={expandedExtra === "locationCodes"}
            onChange={handleExpandedExtra("locationCodes")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`location-codes-content`}
              id={`location-codes-header`}
              sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
            >
              <Typography sx={{ ml: 2 }}>Location Codes</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 3 }}>
              {Object.keys(LocationType)
                .filter((key) => Number(key))
                .map((type, i) => (
                  <Box sx={{ mb: 2 }}>
                    <Typography sx={{ mb: 2 }}>
                      {LocationType[Number(type)]}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="player">
                        <TableHead>
                          <TableRow>
                            <TableCell width={"20%"}>Code</TableCell>
                            <TableCell>Label</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {locationOptions
                            .filter((row) => row.type === Number(type))
                            .map((row, i) => (
                              <TableRow>
                                <TableCell>
                                  {row.type}
                                  {row.code}
                                </TableCell>
                                <TableCell>{row.label}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  )
}
