import SearchIcon from "@mui/icons-material/Search"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputAdornment from "@mui/material/InputAdornment"
import ListSubheader from "@mui/material/ListSubheader"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { useMemo, useState } from "react"

import environment from "../environment"
import { Game } from "../models/game"
import {
  LocationOption,
  LocationType,
  locationOptions,
  parseLocation,
} from "../models/location"
import { setLocationCookie } from "../utils/cookie"
import Flag from "./Flag"

interface LocationProps {
  game: Game
  location: string
  setLocation: React.Dispatch<React.SetStateAction<string>>
}

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

export default function Locations(props: LocationProps) {
  const { game, location, setLocation } = props
  const [searchText, setSearchText] = useState("")
  const [stateCountryOptions, setStateCountryOptions] = useState([] as LocationOption[])

  function handleClick() {
    if (!stateCountryOptions.length) {
      fetch(`${environment.domain}/api/${game}/countries`)
        .then((res) => res.json() as unknown as any)
        .then((res) => {
          const rows = res.rows as any[]
          const tempStateCountryOptions = rows.map((row) => {
            return {
              type: LocationType.Country,
              code: row.alpha_3,
              label: row.country,
            } as LocationOption
          })
          setStateCountryOptions(tempStateCountryOptions)
        })
    }
  }

  const internationalOptions = useMemo(
    () =>
      locationOptions
        .filter((x) => x.type === LocationType.International)
        .filter((x) => containsText(x.label, searchText)),
    [searchText]
  )

  const continentOptions = useMemo(
    () =>
      locationOptions
        .filter((x) => x.type === LocationType.Continent)
        .filter((x) => containsText(x.label, searchText)),
    [searchText]
  )

  const countryOptions = useMemo(
    () =>
      stateCountryOptions
        .filter((x) => x.type === LocationType.Country)
        .filter((x) => containsText(x.label, searchText)),
    [searchText, stateCountryOptions]
  )

  const miscellaneousOptions = useMemo(
    () =>
      locationOptions
        .filter((x) => x.type === LocationType.Miscellaneous)
        .filter((x) => containsText(x.label, searchText)),
    [searchText]
  )

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          IconComponent={() => null}
          sx={(theme) => ({
            width: "fit-content",
            height: "42px",
            borderRadius: "2rem",
            backgroundColor: theme.palette.background.default,
            paddingLeft: "6px",
            "& div": {
              paddingRight: "10px !important",
            },
          })}
          size="small"
          MenuProps={{ autoFocus: false }}
          value={location}
          onChange={(e) => {
            const location = e.target.value
            setLocationCookie(location)
            setLocation(location)
          }}
          onFocus={() => handleClick()}
          onClose={() => setSearchText("")}
          renderValue={(value) => {
            return (
              <Box sx={{ display: "flex", p: 0 }}>
                <Flag location={value} height={18} width={27} />
                <span style={{ marginLeft: "10px" }}>
                  {parseLocation(value)?.label}
                </span>
              </Box>
            )
          }}
        >
          {/* TextField is put into ListSubheader so that it doesn't
                act as a selectable item in the menu
                i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation()
                }
              }}
            />
          </ListSubheader>
          {internationalOptions.length ? (
            <ListSubheader>International</ListSubheader>
          ) : null}
          {internationalOptions.length
            ? internationalOptions.map((option, i) => (
                <MenuItem key={i} value={`${option.type}${option.code}`}>
                  <Flag
                    location={`${option.type}${option.code}`}
                    tooltip={false}
                    height={14}
                    width={21}
                  />
                  <span style={{ marginLeft: "10px" }}>{option.label}</span>
                </MenuItem>
              ))
            : null}
          {continentOptions.length ? (
            <ListSubheader>Continents</ListSubheader>
          ) : null}
          {continentOptions.length
            ? continentOptions.map((option, i) => (
                <MenuItem key={i} value={`${option.type}${option.code}`}>
                  <Flag
                    location={`${option.type}${option.code}`}
                    tooltip={false}
                    height={14}
                    width={21}
                  />
                  <span style={{ marginLeft: "10px" }}>{option.label}</span>
                </MenuItem>
              ))
            : null}
          {countryOptions.length ? (
            <ListSubheader>Countries</ListSubheader>
          ) : null}
          {countryOptions.length
            ? countryOptions.map((option, i) => (
                <MenuItem key={i} value={`${option.type}${option.code}`}>
                  <Flag
                    location={`${option.type}${option.code}`}
                    tooltip={false}
                    height={14}
                    width={21}
                  />
                  <span style={{ marginLeft: "10px" }}>{option.label}</span>
                </MenuItem>
              ))
            : null}
          {miscellaneousOptions.length ? (
            <ListSubheader>Miscellaneous</ListSubheader>
          ) : null}
          {miscellaneousOptions.length
            ? miscellaneousOptions.map((option, i) => (
                <MenuItem key={i} value={`${option.type}${option.code}`}>
                  <Flag
                    location={`${option.type}${option.code}`}
                    tooltip={false}
                    height={14}
                    width={21}
                  />
                  <span style={{ marginLeft: "10px" }}>{option.label}</span>
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
    </Box>
  )
}
