function paramsToObjects(params) {
  const arr = params?.split("/")?.map((x) => x.split("?")?.[0]) ?? []
  const game =
    arr[1] === "mk8" || arr[1] === "mk8dx" ? arr[1].toUpperCase() : null
  const page = game ? arr[2] : arr[1]
  const id = game && page ? arr[3] : null

  // TODO: When adding DX update the player description properly
  const shouldFetchPlayerInfo = game === "MK8" && page === "players" && id

  return { game, page, id, shouldFetchPlayerInfo }
}

function getOpenGraphMeta(domain, params, playerInfo) {
  const objs = paramsToObjects(params)
  const game = objs.game
  const page = objs.page
  const id = objs.id

  let og = {
    title: "MK Database: Mario Kart Database",
    description:
      "MK Database is where players can automatically view their Mario Kart 8 and Mario Kart 8 Deluxe time trial rankings.",
    image: domain + "/banner.png",
    url: domain + params,
  }

  if (game && page === "wrs") {
    og.title = `MK Database: ${game} World Records`
    og.description = `${game} World Records`
  }
  if (game && page === "trs" && !id) {
    og.title = `MK Database: ${game} Track Rankings`
    og.description = `${game} Track Rankings`
  }
  if (game && page === "trs" && id) {
    og.title = `MK Database: ${game} Track Rankings`
    og.description = `${game} Track Rankings`
  }
  if (game && page === "players") {
    og.title = `MK Database: ${game} Players`
    og.description = `${game} Players`
  }
  if (game && page === "players" && playerInfo) {
    const player = playerInfo.player

    // TODO: Get rid of this static 48 (typescript)
    const totalTimes = `${playerInfo.totalCount} / 48`
    const ftRank =
      !player.is_alt && player.ft_150_rank
        ? `${player.ft_150_rank} / ${player.ft_150_rank_total}`
        : "???"
    const nftRank =
      !player.is_alt && player.nft_150_rank
        ? `${player.nft_150_rank} / ${player.nft_150_rank_total}`
        : "???"
    og.title = `MK Database: ${game} - ${player.nnid}`
    og.description =
      `Name: ${player.name}\n` +
      `Nation: ${player.country}\n` +
      `Region: ${player.region}\n` +
      `Total Times: ${totalTimes}\n` +
      `FT Rank: ${ftRank}\n` +
      `Non-FT Rank: ${nftRank}`
    og.image = `${domain}/api/mii/${player.mii}`
  }
  if (game && page === "prs") {
    og.title = `MK Database: ${game} Player Rankings`
    og.description = `${game} Player Rankings`
  }
  if (page === "faq") {
    og.title = `MK Database: FAQ`
    og.description = `FAQ`
  }
  if (page === "dev") {
    og.title = `MK Database: Developer`
    og.description = `Developer`
  }

  return og
}

module.exports = { paramsToObjects, getOpenGraphMeta }
