import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { useState } from "react"

export default function Questions() {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const questions = [
    {
      id: "question-nex",
      question: <span>Where do you get all that sweet data?</span>,
      answer: (
        <span>
          Nintendo's networking library for game servers (NEX). You can read
          more about it{" "}
          <Link
            component="button"
            underline="none"
            onClick={() =>
              window.open(
                "https://github.com/kinnay/NintendoClients/wiki",
                "_blank"
              )
            }
            sx={{
              verticalAlign: "unset",
              fontFamily: "Quicksand,Azeret Mono",
              fontSize: "1rem",
            }}
          >
            here
          </Link>
          .
        </span>
      ),
    },
    {
      id: "question-often",
      question: <span>How often do you update the data?</span>,
      answer: (
        <span>
          There is no job scheduler at the time being, so periodically (once a
          week).
        </span>
      ),
    },
    {
      id: "question-filter",
      question: (
        <span>How do you filter hacked, banned, and backlisted times?</span>
      ),
      answer: <span>With a lot work, research, and community knowledge.</span>,
    },
    {
      id: "question-alt",
      question: <span>What is an alternate (alt) account?</span>,
      answer: (
        <span>
          Players sometimes use various accounts when they play. Reasons vary
          greatly, some for shroomless times, others for time trials with a
          different control scheme, etc.
        </span>
      ),
    },
    {
      id: "question-rankings",
      question: <span>How are rankings calculated? What is FT?</span>,
      answer: (
        <span>
          Rankings are calculated by Average Finish (AF), which is a player's
          average rank per track. FT stands for Full Timesheet and is much more
          respected than Non-FT ranks because unknown times are calculated as
          rank 1001.
        </span>
      ),
    },
  ]

  return (
    <Box component="section">
      <Typography variant="h5">FAQ</Typography>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={9}>
              {questions.map((question) => (
                <Accordion
                  key={question.id}
                  disableGutters={true}
                  elevation={0}
                  sx={{
                    "&.Mui-expanded:before": {
                      opacity: 1,
                    },
                  }}
                  expanded={expanded === question.id}
                  onChange={handleChange(question.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${question.id}-content`}
                    id={`${question.id}-header`}
                    sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
                  >
                    <Typography>{question.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pb: 3 }}>
                    <Typography color="text.secondary">
                      {question.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item xs={3}>
              <Box
                alt="FAQ"
                component="img"
                src={require("../assets/item_box.png")}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
