import DarkModeIcon from "@mui/icons-material/DarkMode"
import LightModeIcon from "@mui/icons-material/LightMode"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import moment from "moment"
import React, { useEffect, useState } from "react"

import environment from "../environment"
import { DrawerInfo } from "../models/drawer"
import { Game } from "../models/game"
import { setModeCookie } from "../utils/cookie"

interface MainAppBarProps {
  drawerInfo: DrawerInfo
  game: Game
  setMode: React.Dispatch<React.SetStateAction<"light" | "dark">>
  mode: string
}

export default function MainAppBar(props: MainAppBarProps) {
  const { drawerInfo, game, setMode, mode } = props

  const [lastUpdated, setLastUpdated] = useState("")
  const [color, setColor] = useState<"primary" | "secondary">("primary")
  const [max, setMax] = useState("")

  useEffect(() => {
    setColor(game === Game.MK8 ? "primary" : "secondary")
    fetch(`${environment.domain}/api/${game}/last`)
      .then((res) => res.json())
      .then((res) => {
        const lastUpdated = moment(res.rows[0].max).fromNow()
        setLastUpdated(lastUpdated)
        setMax(new Date(res.rows[0].max).toUTCString())
      })
  }, [game])

  return (
    <AppBar
      color={color}
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerInfo.width}px)`,
        ml: `${drawerInfo.width}px`,
      }}
    >
      <Toolbar>
        <Typography variant="body1">
          Data Last Updated:{" "}
          <Tooltip title={max} placement="bottom">
            <span
              style={{
                textDecorationStyle: "dotted",
                textDecorationLine: "underline",
                textDecorationColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              {lastUpdated}
            </span>
          </Tooltip>
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => {
            const nextMode = mode === "light" ? "dark" : "light"
            setModeCookie(nextMode)
            setMode(nextMode)
          }}
          color="inherit"
        >
          {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
