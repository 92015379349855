import { Game } from "./game"

interface Track {
  id: number
  name: string
  cup: number
}

export const tracksMap: Record<Game, Track[]> = {
  [Game.MK8]: [
    { id: 27, name: "Mario Kart Stadium", cup: 0 },
    { id: 28, name: "Water Park", cup: 0 },
    { id: 19, name: "Sweet Sweet Canyon", cup: 0 },
    { id: 17, name: "Thwomp Ruins", cup: 0 },
    { id: 16, name: "Mario Circuit", cup: 1 },
    { id: 18, name: "Toad Harbor", cup: 1 },
    { id: 20, name: "Twisted Mansion", cup: 1 },
    { id: 21, name: "Shy Guy Falls", cup: 1 },
    { id: 26, name: "Sunshine Airport", cup: 2 },
    { id: 29, name: "Dolphin Shoals", cup: 2 },
    { id: 25, name: "Electrodrome", cup: 2 },
    { id: 24, name: "Mount Wario", cup: 2 },
    { id: 23, name: "Cloudtop Cruise", cup: 3 },
    { id: 22, name: "Bone-Dry Dunes", cup: 3 },
    { id: 30, name: "Bowser's Castle", cup: 3 },
    { id: 31, name: "Rainbow Road", cup: 3 },
    { id: 33, name: "Wii Moo Moo Meadows", cup: 4 },
    { id: 38, name: "GBA Mario Circuit", cup: 4 },
    { id: 36, name: "DS Cheep Cheep Beach", cup: 4 },
    { id: 35, name: "N64 Toad's Turnpike", cup: 4 },
    { id: 42, name: "GCN Dry Dry Desert", cup: 5 },
    { id: 41, name: "SNES Donut Plains 3", cup: 5 },
    { id: 34, name: "N64 Royal Raceway", cup: 5 },
    { id: 32, name: "3DS DK Jungle", cup: 5 },
    { id: 46, name: "DS Wario Stadium", cup: 6 },
    { id: 37, name: "GCN Sherbet Land", cup: 6 },
    { id: 39, name: "3DS Music Park", cup: 6 },
    { id: 45, name: "N64 Yoshi Valley", cup: 6 },
    { id: 44, name: "DS Tick-Tock Clock", cup: 7 },
    { id: 43, name: "3DS Piranha Plant Slide", cup: 7 },
    { id: 40, name: "Wii Grumble Volcano", cup: 7 },
    { id: 47, name: "N64 Rainbow Road", cup: 7 },
    { id: 56, name: "GCN Yoshi Circuit", cup: 8 },
    { id: 53, name: "Excitebike Arena", cup: 8 },
    { id: 50, name: "Dragon Driftway", cup: 8 },
    { id: 49, name: "Mute City", cup: 8 },
    { id: 57, name: "Wii Wario's Gold Mine", cup: 9 },
    { id: 58, name: "SNES Rainbow Road", cup: 9 },
    { id: 55, name: "Ice Ice Outpost", cup: 9 },
    { id: 51, name: "Hyrule Circuit", cup: 9 },
    { id: 61, name: "GCN Baby Park", cup: 10 },
    { id: 62, name: "GBA Cheese Land", cup: 10 },
    { id: 54, name: "Wild Woods", cup: 10 },
    { id: 52, name: "Animal Crossing", cup: 10 },
    { id: 60, name: "3DS Neo Bowser City", cup: 11 },
    { id: 59, name: "GBA Ribbon Road", cup: 11 },
    { id: 48, name: "Super Bell Subway", cup: 11 },
    { id: 63, name: "Big Blue", cup: 11 },
  ],
  [Game.MK8DX]: [
    { id: 11, name: "Mario Kart Stadium", cup: 0 },
    { id: 12, name: "Water Park", cup: 0 },
    { id: 3, name: "Sweet Sweet Canyon", cup: 0 },
    { id: 1, name: "Thwomp Ruins", cup: 0 },
    { id: 0, name: "Mario Circuit", cup: 1 },
    { id: 2, name: "Toad Harbor", cup: 1 },
    { id: 4, name: "Twisted Mansion", cup: 1 },
    { id: 5, name: "Shy Guy Falls", cup: 1 },
    { id: 10, name: "Sunshine Airport", cup: 2 },
    { id: 13, name: "Dolphin Shoals", cup: 2 },
    { id: 9, name: "Electrodrome", cup: 2 },
    { id: 8, name: "Mount Wario", cup: 2 },
    { id: 7, name: "Cloudtop Cruise", cup: 3 },
    { id: 6, name: "Bone-Dry Dunes", cup: 3 },
    { id: 14, name: "Bowser's Castle", cup: 3 },
    { id: 15, name: "Rainbow Road", cup: 3 },
    { id: 17, name: "Wii Moo Moo Meadows", cup: 4 },
    { id: 22, name: "GBA Mario Circuit", cup: 4 },
    { id: 20, name: "DS Cheep Cheep Beach", cup: 4 },
    { id: 19, name: "N64 Toad's Turnpike", cup: 4 },
    { id: 26, name: "GCN Dry Dry Desert", cup: 5 },
    { id: 25, name: "SNES Donut Plains 3", cup: 5 },
    { id: 18, name: "N64 Royal Raceway", cup: 5 },
    { id: 16, name: "3DS DK Jungle", cup: 5 },
    { id: 30, name: "DS Wario Stadium", cup: 6 },
    { id: 21, name: "GCN Sherbet Land", cup: 6 },
    { id: 23, name: "3DS Music Park", cup: 6 },
    { id: 29, name: "N64 Yoshi Valley", cup: 6 },
    { id: 28, name: "DS Tick-Tock Clock", cup: 7 },
    { id: 27, name: "3DS Piranha Plant Slide", cup: 7 },
    { id: 24, name: "Wii Grumble Volcano", cup: 7 },
    { id: 31, name: "N64 Rainbow Road", cup: 7 },
    { id: 40, name: "GCN Yoshi Circuit", cup: 8 },
    { id: 37, name: "Excitebike Arena", cup: 8 },
    { id: 34, name: "Dragon Driftway", cup: 8 },
    { id: 33, name: "Mute City", cup: 8 },
    { id: 41, name: "Wii Wario's Gold Mine", cup: 9 },
    { id: 42, name: "SNES Rainbow Road", cup: 9 },
    { id: 39, name: "Ice Ice Outpost", cup: 9 },
    { id: 35, name: "Hyrule Circuit", cup: 9 },
    { id: 45, name: "GCN Baby Park", cup: 10 },
    { id: 46, name: "GBA Cheese Land", cup: 10 },
    { id: 38, name: "Wild Woods", cup: 10 },
    { id: 36, name: "Animal Crossing", cup: 10 },
    { id: 44, name: "3DS Neo Bowser City", cup: 11 },
    { id: 43, name: "GBA Ribbon Road", cup: 11 },
    { id: 32, name: "Super Bell Subway", cup: 11 },
    { id: 47, name: "Big Blue", cup: 11 },
    // { id: -1, name: "Tour Paris Promenade", cup: 12 },
    // { id: -1, name: "3DS Toad Circuit", cup: 12 },
    // { id: -1, name: "N64 Choco Mountain", cup: 12 },
    // { id: -1, name: "Wii Coconut Mall", cup: 12 },
    // { id: -1, name: "Tour Tokyo Blur", cup: 13 },
    // { id: -1, name: "DS Shroom Ridge", cup: 13 },
    // { id: -1, name: "GBA Sky Garden", cup: 13 },
    // { id: -1, name: "Tour Ninja Hideaway", cup: 13 },
  ],
}
