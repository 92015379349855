import { createSearchParams } from "react-router-dom"

interface CustomSearchParams {
  full?: boolean
  highlight?: number
  location?: string
  speed?: string
}

export function createCustomSearchParams(
  customSearchParams: CustomSearchParams
) {
  let params: CustomSearchParams = {}
  if (customSearchParams.full !== undefined) {
    params.full = customSearchParams.full
  }
  if (customSearchParams.highlight) {
    params.highlight = customSearchParams.highlight
  }
  if (customSearchParams.location) {
    params.location = customSearchParams.location
  }
  if (customSearchParams.speed) {
    params.speed = customSearchParams.speed
  }
  return createSearchParams(params as any)
}

export function equalSearchParams(a: URLSearchParams, b: URLSearchParams) {
  a.sort()
  b.sort()
  return a.toString() === b.toString()
}
