import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React from "react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Flag from "../components/Flag"
import environment from "../environment"
import { getLocationCookie } from "../utils/cookie"

interface PlayerOption {
  id: string
  label: string
  alpha3: string
  country: string
}

export default function Players() {
  const { game } = useParams()

  const [loading, setLoading] = useState(false)
  const [players, setPlayers] = useState([] as PlayerOption[])
  const [searchTerm, setSearchTerm] = useState(null)
  const [search, setSearch] = useState(null)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm)
    }, 250)
  
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const navigate = useNavigate()

  useEffect(() => {
    if (!search) {
      setPlayers([])
      return
    }
    setLoading(true)
    fetch(`${environment.domain}/api/${game}/players?search=${search}`)
      .then((res) => res.json() as unknown as any)
      .then((res) => {
        const rows = res.rows as any[]
        let players: PlayerOption[] = []
        rows.forEach((val) => {
          players.push({
            id: val.pid,
            label:
              val.name +
              " (nnid: " +
              val.nnid +
              ")" +
              (val.is_alt ? " [alt]" : ""),
            alpha3: val.alpha_3,
            country: val.country,
          })
        })
        setPlayers(players)
        setLoading(false)
      })
  }, [game, search])

  function getHighlightedText(text: string, highlight: string) {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
      <span style={{ marginLeft: "10px" }}>
        {parts.map((part) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span style={{ backgroundColor: "rgba(255, 255, 0, 0.25)" }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    )
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Players
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Your player name will display here if you have at least{" "}
        <b>one uploaded top 1000</b> ghost time.
      </Typography>
      <Autocomplete
        onChange={(event: any, newValue: any) => {
          navigate(
            `/${game}/players/${newValue.id}?location=${getLocationCookie()}`
          )
        }}
        onInputChange={(event: any, newValue: any) => {
          setSearchTerm(newValue)
        }}
        id="players"
        options={players}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
        })}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Flag
                alpha3={option.alpha3}
                country={option.country}
                tooltip={true}
              />
              {getHighlightedText(option.label, search ?? "")}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Player Search"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}
