import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

export default function TableLoading() {
  return (
    <Box>
      {[...Array(6)].map(() => [
        <Skeleton height={80} />,
        <Skeleton />,
        <Skeleton />,
      ])}
    </Box>
  )
}
