import Tooltip from "@mui/material/Tooltip"
import lookup from "country-code-lookup"
import { hasFlag } from "country-flag-icons"
import Flags from "country-flag-icons/react/3x2"

import { LocationType, parseLocation } from "../models/location"

function getCountryISO2(alpha3: any) {
  return lookup.countries.find((x) => x.iso3 === alpha3)?.iso2 ?? ""
}

interface FlagProps {
  alpha3?: string
  country?: string
  location?: string
  tooltip?: boolean
  height?: number
  width?: number
}

export default function Flag(props: FlagProps) {
  let { alpha3, country } = props
  const { location, tooltip, height, width } = props
  let Flag: any = null

  if (location) {
    const locationOption = parseLocation(location)
    if (locationOption?.type === LocationType.Country) {
      alpha3 = locationOption?.code
      country = locationOption?.label
    }
  }

  const alpha2 = getCountryISO2(alpha3)

  if (hasFlag(alpha2)) {
    // @ts-ignore
    Flag = Flags[alpha2]
  }

  function imageElement() {
    try {
      return (
        <img
          src={require("../assets/flags/" + location + ".svg")}
          alt={location}
          height={`${height ?? 12}px`}
        ></img>
      )
    } catch {
      return (
        <div
          style={{
            position: "relative",
            top: "2px",
            height: `${height ?? 12}px`,
            width: `${width ?? 18}px`,
          }}
        ></div>
      )
    }
  }

  const element = Flag ? (
    <span>
      <Flag
        style={{
          position: "relative",
          top: "2px",
          height: `${height ?? 12}px`,
          width: `${width ?? 18}px`,
        }}
      />
    </span>
  ) : (
    <span
      style={{
        position: "relative",
        top: "2px",
      }}
    >
      {imageElement()}
    </span>
  )
  return tooltip ? (
    <Tooltip title={country ?? ""} placement="right">
      {element}
    </Tooltip>
  ) : (
    element
  )
}
