import Tooltip from "@mui/material/Tooltip"

interface CupProps {
  cupName: string
  height: string
}

export default function CupEmblem(props: CupProps) {
  const { cupName, height } = props

  return (
    <Tooltip title={cupName + " Cup"} placement="right">
      <span>
        <img
          src={require("../assets/cups/" + cupName.toLowerCase() + ".png")}
          alt={cupName + " Cup"}
          height={height}
          style={{
            filter: "drop-shadow(0px 0px 3px rgba(127, 127, 127, 0.5))",
          }}
        />
      </span>
    </Tooltip>
  )
}
