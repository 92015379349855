import Cookies from "js-cookie"

import { LocationType } from "../models/location"

export function setLocationCookie(location: string) {
  Cookies.set("location", location, { expires: 365 })
}

export function getLocationCookie(): string {
  const defaultLocationName = `${LocationType.International}WWD`
  const locationName = Cookies.get("location")
  return locationName ?? defaultLocationName
}

export function setModeCookie(mode: "light" | "dark") {
  Cookies.set("mode", mode, { expires: 365 })
}

export function getModeCookie(defaultMode: "light" | "dark"): "light" | "dark" {
  const mode = Cookies.get("mode") as "light" | "dark"
  return mode ?? defaultMode
}
