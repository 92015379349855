import Box from "@mui/material/Box"
import Fade from "@mui/material/Fade"
import { useEffect, useState } from "react"

import environment from "../environment"

interface MiiProps {
  data: string
  height: string
  ftRank: number
  nftRank: number
  nftRankTotal: number
}

export default function Mii(props: MiiProps) {
  const { data, height, ftRank, nftRank, nftRankTotal } = props
  const [loaded, setLoaded] = useState(false)
  const [upsideDown, setUpsideDown] = useState(false)
  const [shiny, setShiny] = useState(false)
  const [src, setSrc] = useState("")

  useEffect(() => {
    setLoaded(false)
    
    // function expression() {
    //   const pct = nftRank / nftRankTotal
    //   const top10Pct = pct && pct < 0.1
    //   const bottom10Pct = pct && pct > 0.9
    //   const winkRight = Math.floor(Math.random() * 16) === 0
    //   const winkLeft = Math.floor(Math.random() * 16) === 0

    //   if (ftRank || top10Pct) return "smile"
    //   else if (bottom10Pct) return "sorrow"
    //   else if (winkRight) return "like_wink_left"
    //   else if (winkLeft) return "like_wink_right"
    //   return "normal"
    // }

    setUpsideDown(Math.floor(Math.random() * 256) === 0)
    setShiny(Math.floor(Math.random() * 8192) === 0)

    setSrc(`${environment.domain}/api/mii/${data}`)
  }, [data])

  return (
    <Box height={`${height}px`} width={`${height}px`} margin="0 auto">
      {data && src ? (
        <Fade in={loaded} timeout={150}>
          <img
            src={src}
            alt={data}
            height={height}
            width={height}
            style={{
              transform: upsideDown ? "rotate(180deg)" : "rotate(0deg)",
              filter: `drop-shadow(0px 0px 3px rgba(127, 127, 127, 0.5)) ${
                shiny ? "invert(1)" : ""
              }`,
            }}
            onLoad={() => setLoaded(true)}
          />
        </Fade>
      ) : null}
    </Box>
  )
}
