import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useMemo, useState } from "react"
import { isDesktop } from "react-device-detect"
import { useLocation } from "react-router-dom"

import MainAppBar from "./main/MainAppBar"
import MainContext from "./main/MainContext"
import MainDrawer from "./main/MainDrawer"
import { DrawerInfo } from "./models/drawer"
import { Game } from "./models/game"
import { getModeCookie } from "./utils/cookie"

export default function App() {
  const defaultMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light"

  const [game, setGame] = useState<Game>(Game.MK8)
  const [mode, setMode] = useState<"light" | "dark">(getModeCookie(defaultMode))
  const [drawerInfo, setDrawerInfo] = useState<DrawerInfo>({
    open: isDesktop,
    width: isDesktop ? 240 : 58,
  })

  const location = useLocation()
  const gameParam = location.pathname.split("/")?.[1] as Game
  if (Object.values(Game).includes(gameParam)) {
    if (game !== gameParam) {
      setGame(gameParam)
    }
  }

  const theme = useMemo(() => {
    let theme = createTheme({
      palette: {
        mode,
      },
      typography: {
        fontFamily: "'Quicksand','Azeret Mono'",
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontFamily: "Azeret Mono",
              fontSize: "0.75rem",
              lineHeight: 1.25,
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              fontFamily: "Azeret Mono",
              fontSize: "0.75rem",
              lineHeight: 1.25,
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              ...(mode === "light"
                ? {
                    backgroundImage: `url(${require("./assets/background_light.png")})`,
                  }
                : {
                    backgroundImage: `url(${require("./assets/background_dark.png")})`,
                  }),
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              ":last-child": {
                paddingBottom: "16px",
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              ...(mode === "light"
                ? {
                    backgroundColor: "rgba(251, 251, 251, 1)",
                  }
                : null),
            },
          },
        },
      },
    })
    return createTheme(theme, {
      components: {
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    })
  }, [mode])

  // .css-18k6se0-MuiPaper-root-MuiAccordion-root

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <MainAppBar
          drawerInfo={drawerInfo}
          game={game}
          setMode={setMode}
          mode={mode}
        />
        <MainDrawer
          drawerInfo={drawerInfo}
          setDrawerInfo={setDrawerInfo}
          game={game}
          setGame={setGame}
        />
        <MainContext />
      </Box>
    </ThemeProvider>
  )
}
