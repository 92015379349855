import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"

import environment from "../environment"

export default function Welcome() {
  const navigate = useNavigate()

  return (
    <Box>
      <Typography variant="h5">Welcome</Typography>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography component={"span"}>
            MK Database is where players can automatically view their Mario Kart
            8 and Mario Kart 8 Deluxe time trial rankings. You must have at
            least one uploaded top 1000 ghost time for your account to appear.
            <br />
            <br />
            Please read the{" "}
            <Link
              component="button"
              underline="none"
              onClick={() => navigate("/faq")}
              sx={{
                verticalAlign: "unset",
                fontFamily: "Quicksand,Azeret Mono",
                fontSize: "1rem",
              }}
            >
              FAQ
            </Link>{" "}
            and contact us on{" "}
            <Link
              component="button"
              underline="none"
              onClick={() => window.open(environment.discord, "_blank")}
              sx={{
                verticalAlign: "unset",
                fontFamily: "Quicksand,Azeret Mono",
                fontSize: "1rem",
              }}
            >
              discord
            </Link>{" "}
            if you have any concerns, questions, or suggestions.
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h5">Release Notes</Typography>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">December 14, 2023</Typography>
          <Typography color="text.secondary">Version 1.6</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>Custom player page Open Graph embeds.</Typography>
              </li>
              <li>
                <Typography>Better SEO.</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">August 28, 2023</Typography>
          <Typography color="text.secondary">Version 1.5</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>Cache Miis.</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">August 20, 2022</Typography>
          <Typography color="text.secondary">Version 1.4</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>User Miis.</Typography>
              </li>
              <li>
                <Typography>
                  Location filtering (International, Continent, Country, and
                  Miscellaneous).
                </Typography>
              </li>
              <li>
                <Typography>
                  Better navigation for UX and link sharing.
                </Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">July 5, 2022</Typography>
          <Typography color="text.secondary">Version 1.3</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>FAQ.</Typography>
              </li>
              <li>
                <Typography>Developer page (Public API).</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">June 18, 2022</Typography>
          <Typography color="text.secondary">Version 1.2</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>Player rankings.</Typography>
              </li>
              <li>
                <Typography>Player search now includes nnids.</Typography>
              </li>
              <li>
                <Typography>Alternate account indicators.</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">June 8, 2022</Typography>
          <Typography color="text.secondary">Version 1.1</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>Better mobile UI.</Typography>
              </li>
              <li>
                <Typography>Blank player names are now clickable.</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">June 3, 2022</Typography>
          <Typography color="text.secondary">Version 1.0</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>Real MK8 Data.</Typography>
              </li>
              <li>
                <Typography>Support toggling between MK8 and MK8DX.</Typography>
              </li>
              <li>
                <Typography>
                  Nation, motion control, and region info.
                </Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Typography color="text.secondary">May 1, 2022</Typography>
          <Typography color="text.secondary">Version 0.1</Typography>
          <Typography component={"span"}>
            <ul>
              <li>
                <Typography>Initial deployment with dummy data.</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
